$('.switch').hover(function() {
        $(this).find('.kachel').hide();
        $(this).find('.kachel-hover').addClass("visible");
        $(this).find('.kachel-hover.visible').show();
    }, function() {
        $(this).find('.kachel-hover').hide();
        $(this).find('.kachel').show();
});

$(document).ready(function(){
  $('.fade').slick({
    dots: false,
    arrows: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear'
  });
  $('.slider-for').slick({
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   fade: true,
   asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
   slidesToShow: 3,
   slidesToScroll: 1,
   asNavFor: '.slider-for',
   dots: false,
   centerMode: true,
   focusOnSelect: true
  });

});




$('.header-mainnav > ul > li:has(ul)').addClass("has-subnav");

$(".search-toggle").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close, .overlay").toggleClass("search-active");
    $(".bookmarks-wrapper, .overlay").removeClass("bookmarks-active");
    $("body").toggleClass("no-scroll-search");
    $("body.no-scroll-bookmarks").removeClass("no-scroll-bookmarks");

    setTimeout("$('.search-wrapper > input:first-child').focus();", 500);
});

$(".search-close").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close, .overlay").removeClass("search-active");
    $("body").removeClass("no-scroll-search");
    $("body.no-scroll-bookmarks").removeClass("no-scroll-bookmarks");
});


$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".bookmarks-wrapper").removeClass("bookmarks-active");
        $(".overlay").removeClass("active search-active bookmarks-active open-position-active");
        $(".hamburger").removeClass("is-active");
        $("#main-wrapper, .overlay").removeClass("off-canvas-active");
        $("#off-canvas").removeClass("active");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $('#lang-select > ul > li:first-child, #lang-select > ul > li >ul').removeClass("active");
        $(".prod-category-item-wrapper, .prod-item-content").removeClass("active");
        $(".filter-select-wrapper ul").removeClass("open");
        $(".popup").removeClass("active");
    }
});

$(".overlay").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
    $(".bookmarks-wrapper").removeClass("bookmarks-active");
    $(".overlay").removeClass("active search-active bookmarks-active open-position-active");
    $(".hamburger").removeClass("is-active");
    $("#main-wrapper, .overlay").removeClass("off-canvas-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
    $(".popup").removeClass("active");
});

$(".header-mainnav ul li.has-subnav").mouseover(function () {
    $(".overlay").addClass("menu-active");
});

$(".header-mainnav").mouseout(function () {
    $(".overlay").removeClass("menu-active");
});

$(".filter-select-wrapper ul").click(function () {
    $(this).toggleClass("open");
});

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");
    $(".search-wrapper, .search-close, .bookmarks-toggle, .overlay").removeClass("search-active");
    $(".bookmarks-wrapper, .overlay").removeClass("bookmarks-active");

    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > ul > li:has(ul)').addClass("has-subnav");

$('.acc-menu .first-layer').click(function(e) {
    if ($(e.target).hasClass('first-layer')) {
        $(this).toggleClass('open');
    }
});

$('.acc-menu .second-layer').click(function() {
    $(this).toggleClass('open');
});
